.App {
    font-family: ReadexPro, sans-serif;
    text-align: center;
  }
  
  body {
    margin: 0;
    padding: 0;
  }
  
  html {
    scroll-behavior: smooth;
  }

* {
  cursor: none;
}

.swiper {
  width: 350px;
  height: 420px;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  font-size: 22px;
  font-weight: bold;
  color: #F62D0B;
  background-color: rgb(240,240,240);
}
