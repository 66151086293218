/* @font-face {
    font-family: 'ReadexPro';
    src: local('Readex Pro Bold'), url(./fonts/ReadexPro-VariableFont_HEXP,wght.ttf) format('truetype');
} */

@font-face {
    font-family: 'ReadexPro';
    src: local('Readex Pro Bold'), url(./fonts/ReadexPro-bold.ttf) format('truetype');
    font-weight: bold 700;
}
@font-face {
    font-family: 'ReadexPro';
    src: local('Readex Pro SemiBold'), url(./fonts/ReadexPro-SemiBold.ttf) format('truetype');
    font-weight: 600;
}
@font-face {
    font-family: 'ReadexPro';
    src: local('Readex Pro Medium'), url(./fonts/ReadexPro-Medium.ttf) format('truetype');
    font-weight: 500;
}
@font-face {
    font-family: 'ReadexPro';
    src: local('Readex Pro Regular'), url(./fonts/ReadexPro-Regular.ttf) format('truetype');
    font-weight: normal 400;
}
@font-face {
    font-family: 'ReadexPro';
    src: local('Readex Pro Light'), url(./fonts/ReadexPro-Light.ttf) format('truetype');
    font-weight: 300;
}
@font-face {
    font-family: 'ReadexPro';
    src: local('Readex Pro Extra Light'), url(./fonts/ReadexPro-ExtraLight.ttf) format('truetype');
    font-weight: 200;
}

@font-face {
    font-family: 'Merriweather';
    src: url('./fonts/Merriweather-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Merriweather';
    src: url('./fonts/Merriweather-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Merriweather';
    src: url('./fonts/Merriweather-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Merriweather';
    src: url('./fonts/Merriweather-Italic.otf') format('opentype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Merriweather';
    src: url('./fonts/Merriweather-BoldItalic.otf') format('opentype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Merriweather';
    src: url('./fonts/Merriweather-Black.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Merriweather';
    src: url('./fonts/Merriweather-BlackItalic.otf') format('opentype');
    font-weight: 900;
    font-style: italic;
}
